import React, { useState } from 'react';
import logo from './Julien.png';
import './App.css';

function App() {
  const [text, setText] = useState("FEUR");


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-text">{text}</p>
      </header>
    </div>
  );
}

export default App;
